import React, { useRef, useEffect, useState } from "react";
import cursorImage from "../../../../brush.png";

interface ImageEditCanvasProps {
    image: string;
    cursorSize: number;
    drawnPortion: { x: number; y: number; radius: number }[][];
    setDrawnPortion: React.Dispatch<React.SetStateAction<{ x: number; y: number; radius: number }[][]>>;
    handleMaskGenerated: (mask: string) => void;
    style: React.CSSProperties;
}

const ImageEditCanvas: React.FC<ImageEditCanvasProps> = ({
    image,
    cursorSize,
    drawnPortion,
    setDrawnPortion,
    handleMaskGenerated,
    style
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
    const [imageAspectRatio, setImageAspectRatio] = useState(1);
    const [isDrawing, setIsDrawing] = useState(false);
    const [currentStroke, setCurrentStroke] = useState<{ x: number; y: number; radius: number }[]>([]);
    const [customCursor, setCustomCursor] = useState<string | null>(null);
    const [naturalImageDimensions, setNaturalImageDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
            setImageElement(img);
            setImageAspectRatio(img.width / img.height);
            setNaturalImageDimensions({ width: img.width, height: img.height });
        };
    }, [image]);

    useEffect(() => {
        const tempCanvas = document.createElement("canvas");
        const ctx = tempCanvas.getContext("2d");
        const img = new Image();

        img.src = cursorImage;
        img.onload = () => {
            tempCanvas.width = cursorSize;
            tempCanvas.height = cursorSize;

            if (ctx) {
                ctx.drawImage(img, 0, 0, cursorSize, cursorSize);
                const resizedCursorURL = tempCanvas.toDataURL("image/png");
                setCustomCursor(resizedCursorURL);
            }
        };
    }, [cursorSize]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");

        if (canvas && context && imageElement) {
            canvas.width = naturalImageDimensions.width;
            canvas.height = naturalImageDimensions.height;

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.save();

            context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);

            drawnPortion.forEach((stroke) => {
                stroke.forEach(({ x, y, radius }) => {
                    context.beginPath();
                    context.arc(
                        x * naturalImageDimensions.width,
                        y * naturalImageDimensions.height,
                        radius * naturalImageDimensions.width,
                        0,
                        2 * Math.PI
                    );
                    context.fillStyle = "white";
                    context.fill();
                });
            });
        }
    }, [drawnPortion, imageElement, naturalImageDimensions]);

    const getCanvasCoordinates = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return { x: 0, y: 0 };

        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX - rect.left) / rect.width;
        const y = (event.clientY - rect.top) / rect.height;

        return { x, y };
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        setIsDrawing(true);
        setCurrentStroke([]);
        inpaint(event);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (isDrawing) {
            inpaint(event);
        }
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
        if (currentStroke.length > 0) {
            setDrawnPortion((prev) => [...prev, currentStroke]);
            setCurrentStroke([]);
        }
        generateMask();
    };

    const inpaint = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const { x, y } = getCanvasCoordinates(event);
        const radius = (cursorSize / 2) / Math.max(canvas.width, canvas.height);

        const context = canvas.getContext("2d");
        if (context) {
            context.beginPath();
            context.arc(
                x * naturalImageDimensions.width,
                y * naturalImageDimensions.height,
                radius * naturalImageDimensions.width,
                0,
                2 * Math.PI
            );
            context.fillStyle = "white";
            context.fill();
        }

        setCurrentStroke((prev) => [...prev, { x, y, radius }]);
    };

    const generateMask = () => {
        const maskCanvas = document.createElement("canvas");
        const maskContext = maskCanvas.getContext("2d");

        if (!maskContext || !canvasRef.current) return;

        maskCanvas.width = naturalImageDimensions.width;
        maskCanvas.height = naturalImageDimensions.height;

        maskContext.fillStyle = "black";
        maskContext.fillRect(0, 0, maskCanvas.width, maskCanvas.height);

        drawnPortion.forEach((stroke) => {
            stroke.forEach(({ x, y, radius }) => {
                maskContext.beginPath();
                maskContext.arc(
                    x * naturalImageDimensions.width,
                    y * naturalImageDimensions.height,
                    radius * naturalImageDimensions.width,
                    0,
                    2 * Math.PI
                );
                maskContext.fillStyle = "white";
                maskContext.fill();
            });
        });

        const maskBase64 = maskCanvas.toDataURL("image/png");
        handleMaskGenerated(maskBase64);
    };

    const inpaintingCursor: React.CSSProperties = {
        cursor: customCursor
            ? `url(${customCursor}) ${cursorSize / 2} ${cursorSize / 2}, auto`
            : "auto",
        // cursor: customCursor
        //     ? `url(${customCursor}) ${cursorSize / 2} ${cursorSize / 2}, auto`
        //     : "auto",
    };

    return (
        <div className="relative">
            <canvas
                ref={canvasRef}
                width={naturalImageDimensions.width}
                height={naturalImageDimensions.height}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                style={{
                    ...inpaintingCursor,
                    ...style,
                    width: "100%",
                    height: "auto",
                    // transform: `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`,
                }}
            />
        </div>
    );
};

export default ImageEditCanvas;